<template>
  <app-form-input-select
    v-model="model"
    item-title="label"
    item-value="value"
    :items="items"
    :label="$t('growth.contact_form.company_size_label')"
    prepend-icon="ph:buildings"
    :required="required"
  />
</template>

<script lang="ts" setup>
const model = defineModel({ default: undefined });

defineProps<{
  required?: boolean;
}>();

const { t } = useI18n();
const items = computed(() => {
  return [
    undefined,
    "BETWEEN_1_AND_50",
    "BETWEEN_51_AND_500",
    "BETWEEN_501_AND_2500",
    "MORE_THAN_2500",
  ].map((value) => {
    if (!value) return { label: "", value };

    return {
      label: t(`growth.contact_form.company_sizes.${value}`),
      value,
    };
  });
});
</script>

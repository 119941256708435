<template>
  <app-form-field v-bind="fieldBinding">
    <template #default="{ inputClass }">
      <select
        :class="inputClass"
        :value="value"
        v-on="listeners"
        @keydown.enter.prevent
      >
        <option
          v-for="option in items as Record<string, string>[]"
          :key="option[itemValue]"
          :value="option[itemValue]"
        >
          {{ option[itemTitle] }}
        </option>
      </select>
    </template>
  </app-form-field>
</template>

<script lang="ts" setup generic="T extends Record<string, string>">
import {
  appFormInputProperties,
  useAppFormInput,
} from "../composables/form-input.hook";

const properties = defineProps({
  ...appFormInputProperties,
  items: { type: Array, default: () => [] },
  itemValue: { type: String, required: true },
  itemTitle: { type: String, required: true },
});

const { value, listeners, fieldBinding } = useAppFormInput(properties);
</script>
